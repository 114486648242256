const ScreenLoader = ({placeMiddle = true}) => {

    return ( placeMiddle ?
        <>
            <div className="lds-ring position-absolute top-50 start-50 translate-middle"><div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </> 
        :
        <>
            <div className="lds-ring position-absolute start-50 loader-placement-header"><div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    );
};

export default ScreenLoader;