import { Dropdown } from "react-bootstrap";

const PageDropdown = ({ options, handleDropdownChange, pageId }) => {

    return (
        <>
            <Dropdown className="page-dd" data-bs-theme="dark">
                <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                    {(pageId === 0) ? 
                        <>
                            Live Page
                        </>
                        : 
                        <>
                            Page {pageId} 
                        </>
                    }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {options.map((option) => (
                        <Dropdown.Item
                            key={option.id}
                            active={option.id === pageId}
                            onClick={() => handleDropdownChange(option)}
                        >
                            {(option.id === 0) ? "Live Page" : (option.page_name) ? option.page_name : `Page ${option.id}`}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

export default PageDropdown;