import React, { useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios";
import ScreenLoader from "../components/HelperComponents/ScreenLoader";

const ContactUs = () => {
    const modelSuccessMsg = 'Feedback submitted successfully!';
    const modelErrorMsg = 'Failed to submit feedback, please try again later!';

    const captchaRef = useRef(null);
    const [show, setShow] = useState(false);
    const [modelMsg, setModelMsg] = useState(modelSuccessMsg);
    const [showLoader, setShowLoader] = useState(false);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setShowLoader(true);
        const email = e.target.email.value;
        const message = e.target.textarea.value;

        const token = captchaRef.current.getValue();
        captchaRef.current.reset();

        axios({
            method: 'post',
            baseURL: process.env.REACT_APP_API_BASE_URL,
            url: '/contact-us',
            auth: {
                username: process.env.REACT_APP_API_USERNAME,
                password: process.env.REACT_APP_API_PASSWORD
            },
            params: {
                token: token,
                email: email,
                message: message
            }
        }).then(function (response) {
            if (response.error) {
                setModelMsg(modelErrorMsg);
            } else {
                setModelMsg(modelSuccessMsg);
            }
            setShowLoader(false);
            setShow(true);
        }).catch((error) => {
            if (error.response) {
              console.error('Server Error:', error.response.status);
            } else if (error.request) {
              console.error('Network Error:', error.request);
            } else {
              console.error('Error:', error.message);
            }
            setModelMsg(modelErrorMsg);
            setShowLoader(false);
            setShow(true);
          });

        e.target.reset();
    };

    return (showLoader ?
        <>
            <ScreenLoader placeMiddle={true} />
        </>
        :
        <>
            <section className="pageModal contact-section">
                <div className="container h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-12">
                            <h1>Contact Us</h1>

                            <p>
                                We'd love to hear from you! Whether you have a question about our products, need assistance with an order, or just want to give us feedback, we're here to help. Fill out the form below, and we'll get back to you as soon as possible.
                            </p>

                            <form onSubmit={handleFormSubmit} style={{ maxWidth: "500px" }}>
                                <div className="mb-3">
                                    <label className='d-block mb-2'>Email address :</label>
                                    <input type="email" name="email" placeholder="name@example.com" className='mb-3 form-control' style={{ minWidth: '300px' }} required />
                                </div>
                                <div className="mb-3">
                                    <label className='d-block mb-2'>Feedback :</label>
                                    <textarea type="textarea" rows="3" cols="10" name="textarea" placeholder="some feedback" className='mb-3 form-control' style={{ minWidth: '300px', color: "black" }} required></textarea>
                                </div>
                                <ReCAPTCHA
                                    className='mb-3'
                                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                    ref={captchaRef}
                                />
                                <div className='text-center mb-3'>
                                    <Button type="submit" variant="primary" style={{ float: 'left' }}>Submit</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body><span>{modelMsg}</span></Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-primary me-3' onClick={() => setShow(false)}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ContactUs;
