import QRCode from 'qrcode.react';
import copy_url_logo from "../assets/copy_url.svg";

const DownloadApp = () => {

    const size = 300;
    const bgColor = "#eee";
    const fgColor = "#5f1587";
    const appLink = process.env.REACT_APP_MOBILE_APP_LINK

    const CopyToClipboard = (elementId) => {
        var text = document.getElementById(elementId).innerHTML;
        navigator.clipboard.writeText(text);
    }

    return (
        <>
            <section className="pageModal downloadapp-section">
                <div className="container h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-12 pt-3">
                            <h1>Download App</h1>
                            <p className="p-url">
                                <span>You can download our Android app by scaning the below QR code or hitting this link : </span>
                                <br />
                                <a id='app-link-id' className="inst-down-links" href={appLink} target="_blank" rel="noreferrer">{appLink}</a>
                                <span onClick={() => CopyToClipboard('app-link-id')}>
                                    <img className="copy-url mb-2 ms-1" src={copy_url_logo} alt="copy app link" />
                                </span>
                            </p>

                            <QRCode
                                style={{
                                    margin: '0 auto',
                                    display: 'block',
                                    backgroundColor: 'white',
                                }}
                                className='mt-3 p-3'
                                size={size}
                                level='M'
                                includeMargin={false}
                                renderAs="svg"
                                bgColor={bgColor}
                                fgColor={fgColor}
                                value={appLink}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DownloadApp;