import React, { useState, useEffect } from "react";
import { onSnapshot, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase-config';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import PageView from "../PageUtilComponents/PageView";
import PageHeader from "../PageUtilComponents/PageHeader";
// import PDFDownload from "../PageUtilComponents/PDFDownload";
// import DownloadAction from "../HelperComponents/DownloadAction"
import ScreenLoader from "../HelperComponents/ScreenLoader";

const Board = ({ DocId }) => {

    const navigate = useNavigate();
    const [docError, setDocError] = useState('');
    const [userError, setUserError] = useState('');
    const [tempUserName, setTempUserName] = useState(null);
    const [endUserStatus, setEndUserStatus] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [isPause, setIsPause] = useState(true);
    const [imgUrl, setImgUrl] = useState('');
    const [sessName, setSessName] = useState('');
    // const [showPDFAlert, setShowPDFAlert] = useState(false);
    // const [showPDFAlertOpacity, setShowPDFAlertOpacity] = useState(false);
    const [endUserName, setEndUserName] = useState(() => {
        const initialValue = localStorage.getItem("endUser");
        return initialValue || null;
    });

    const deleteUserFromLS = () => {
        localStorage.removeItem("endUser");
        setEndUserName(null);
    }

    const verifyNewUser = (e, vUserName) => {
        e.preventDefault();

        localStorage.setItem("endUser", vUserName)

        const endUserRef = doc(db, "end_users", DocId);
        // check if user exist
        getDoc(endUserRef).then(docSnap => {
            if (docSnap.exists()) {
                let listUser = docSnap.data();

                let approved = listUser.approved;
                let pending = listUser.pending;
                let denied = listUser.denied

                let found = false;
                let index = pending.indexOf(vUserName);

                if (index === -1) {
                    index = denied.indexOf(vUserName)

                    if (index === -1) {
                        index = approved.indexOf(vUserName)

                        if (index !== -1) {
                            found = true;
                        }
                    } else {
                        found = true;
                    }
                } else {
                    found = true;
                }

                if (found) {
                    setUserError('Please use another username')
                } else {
                    pending.push(vUserName);
                    setUserError('')
                    setEndUserName(vUserName);
                    updateDoc(endUserRef, {
                        "pending": pending
                    });
                }
            }
        })
    };

    // const generatePDF = () => {
    //     setShowPDFAlert(true);
    //     setShowPDFAlertOpacity(true);
    // };

    // const handleDownloadClick = () => {
    //     setShowPDFAlertOpacity(false);
    //     setTimeout(() => {
    //         setShowPDFAlert(false);
    //     }, 2000);
    // }

    useEffect(() => {
        if (endUserStatus) {
            let firstFlag = true;
            const unsub = onSnapshot(doc(db, "board_session", DocId), { source: 'server' }, (data) => {
                console.log('image snapshot triggered')
                if (data.exists) {
                    setDocError('');
                    const docData = data.data();
                    setIsPause(docData.pause);
                    setSessName(docData.session_name);
                    if (docData.page_id === 0) {
                        if (!isPause || firstFlag) {
                            setImgUrl(docData.data_base64);
                            firstFlag = false;
                        }
                    } else {
                        setImgUrl(docData.page_src);
                        setIsPause(false)
                    }
                } else {
                    setDocError('Please enter a valid session key');
                }
            });

            return () => unsub();
        }

    }, [DocId, isPause, endUserStatus]);

    useEffect(() => {

        const unsub = onSnapshot(doc(db, "end_users", DocId), { source: 'server' }, (docData) => {
            console.log('users snapshot triggered')
            if (docData.exists) {
                setUserError('')
                let listUser = docData.data();
                if (listUser) {
                    let approved = listUser.approved;
                    let index = approved.indexOf(endUserName)

                    if (index !== -1) {
                        setEndUserStatus(true);
                    } else {
                        setEndUserStatus(false);
                    }
                }
            } else {
                setUserError('Error fetching user list')
            }
        });

        return () => unsub();

    }, [DocId, endUserName]);

    return (showLoader ?
        <>
            <ScreenLoader placeMiddle={true} />
            {setTimeout(() => { setShowLoader(false) }, 1500)}
        </>
        :
        <>
            {docError ?
                <>
                    <div className="info-toast info-toast-bottom">
                        <h3>{docError}</h3>
                        <span style={{ color: "#842029", cursor: "pointer" }} onClick={() => navigate('/join-session')}>Click here to re-enter session ID</span>
                    </div>
                </>
                :
                <>
                    {/* {showPDFAlert &&
                        <PDFDownload DocId={DocId} sessName={sessName} handleClick={handleDownloadClick} handleOpacity={showPDFAlertOpacity} />
                    } */}

                    {endUserStatus ?
                        <>
                            {/* <DownloadAction actionOnClick={generatePDF} /> */}
                            <div className="p5 m-auto d-flex flex-column align-items-center">
                                <div className={`session-card ${imgUrl ? 'mw-100' : ''}`}>
                                    <PageHeader sessName={sessName} />
                                    <PageView imgUrl={imgUrl} isPause={isPause} canFS={true}/>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {userError &&
                                <>
                                    <div className="info-toast top-50 start-50 translate-middle info-toast-no-unserline">
                                        <span>{userError}</span>
                                    </div>
                                </>
                            }

                            {endUserName ?
                                <>
                                    <div className="info-toast top-50 start-50 translate-middle info-toast-no-unserline">
                                        {endUserName}, Please wait till the admin approves you request.<br /><br /> If {endUserName} is not you, Please <span className="text-decoration-none link-col" onClick={() => deleteUserFromLS()}>click here</span> to reset.
                                    </div>
                                </>
                                :
                                <div className="p5 m-auto d-flex flex-column align-items-center">
                                    <div className={`session-card`} style={{ width: "400px" }}>
                                        <form onSubmit={(e) => verifyNewUser(e, tempUserName)}>
                                            <input
                                                type="text"
                                                placeholder="Please enter your username"
                                                onChange={(e) => setTempUserName(e.target.value)}
                                                style={{
                                                    outline: (userError ? '2px solid #ff0000' : '0 !important'),
                                                    borderColor: '#480161',
                                                    minWidth: '300px'
                                                }}
                                                className="mb-3 form-control"
                                                required
                                            />
                                            <Button type="submit" className="btn-primary-reverse">Join</Button>
                                        </form>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </>
            }
        </>
    );
}

export default Board;
