import React, { useContext, useEffect } from "react";
// import About from "./About";
import ContactUs from "./ContactUs";
import Instructions from "./Instructions"
import DownloadApp from "./DownloadApp";
import tripod_logo from "../assets/tripod.svg";

import { StoreContext } from "../context/StoreProvider";

const Home = ({ setDirectSection }) => {

    const { setSections } = useContext(StoreContext);

    useEffect(() => {
        setSections(setDirectSection);
    }, [setSections, setDirectSection]);

    return (
        <>
            <section className="pageModal home-section">
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        <div className="col-12 col-md-6 m-0">
                            <h1>Sushboard</h1><h3>- Bring classroom experience to online teaching</h3>
                            <p>
                                Sushboard is a revolutionary edtech tool which will allow tutors to stream handwritten content to students real time.
                            </p>
                            <p>
                                The AI/ML enabled application will need the tutor to only download an app on his/her mobile phone with no other hardware.
                            </p>
                            <p>
                                It will simulate real life classroom teaching remotely, without the need for screen sharing or powerpoint slides.
                            </p>
                            <p>
                                The beauty of the application is that all frill items from the video feed including the hand will get eliminated with the student only seeing the paper and the content.
                            </p>
                        </div>
                        <div className="col-12 col-md-6 m-0 text-center">
                            <img src={tripod_logo} style={{ width: '100%' }} alt="tripod" />
                        </div>
                    </div>
                </div>

            </section>

            {/* <About /> */}

            <ContactUs />

            <Instructions />

            <DownloadApp />
        </>
    );
}

export default Home;