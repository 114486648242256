import React, { useState } from 'react'
import { db } from '../../config/firebase-config';
import { collection, doc, setDoc, serverTimestamp } from "firebase/firestore";
import QRCode from 'qrcode.react';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import copy_url_logo from "../../assets/copy_url.svg";

function CreateNormalSession({ user }) {

    const navigate = useNavigate();
    const black_img = process.env.REACT_APP_GCP_BLACK_IMG_LINK;
    const [docId, setDocId] = useState(null)
    const [sessNameField, setSessNameField] = useState('')
    const [sessionName, setSessionName] = useState('')
    const size = 200;
    const bgColor = "#eee";
    const fgColor = "#5f1587";

    const createNewSession = (e, sname) => {
        e.preventDefault();

        const newDocRef = doc(collection(db, "board_session"));
        const data = {
            "create_on": serverTimestamp(),
            "data_base64": black_img,
            "pause": true,
            "updated_on": '',
            "admin_uid": user.uid,
            "page_id": 0,
            "page_src": ""
        }

        setDoc(newDocRef, data);
        setDoc(doc(db, "end_users", newDocRef.id), {
            "approved": [],
            "pending": [],
            "denied": [],
        });
        setDocId(newDocRef.id);

        setSessionName(sname)
        axios({
            method: 'post',
            baseURL: process.env.REACT_APP_API_BASE_URL,
            url: '/update-session',
            auth: {
                username: process.env.REACT_APP_API_USERNAME,
                password: process.env.REACT_APP_API_PASSWORD
            },
            params: {
                sess_id: newDocRef.id,
                sess_name: sname,
                cust_uid: user.uid
            }
        }).then(function (response) {
            let resp_data = response.data;
            if(resp_data.status === 1){
                // let data = resp_data.data;
                // console.log(data);
            } else {
                console.log(resp_data.error);
            }
        }).catch((error) => {
            if (error.response) {
              console.error('Server Error:', error.response.status);
            } else if (error.request) {
              console.error('Network Error:', error.request);
            } else {
              console.error('Error:', error.message);
            }
        });

        UpdateGCPOnCreate(newDocRef.id)
    };

    const CopyToClipboard = (elementId) => {
        var text = document.getElementById(elementId).innerHTML;
        navigator.clipboard.writeText(text);
    }

    const UpdateGCPOnCreate = (docId) => {
        let data = JSON.stringify({
            "doc_id": docId
        });

        axios({
            method: 'post',
            baseURL: process.env.REACT_APP_GCP_URL,
            url: '/doc-init',
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: process.env.REACT_APP_GCP_API_USERNAME,
                password: process.env.REACT_APP_GCP_API_PASSWORD
            },
            data: data
        }).then(function (response) {
            // console.log(JSON.stringify(response.data));
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            {!sessionName &&
                <>
                    <p>In normal session there is only one broadcaster who is also the admin here and multiple listners who can watch/subscribe to what the broadcaster is broadcasting.</p>
                    <p>Please follow the steps below to create a new session.</p>
                    <br />
                    <br />
                </>
            }

            <>
                <div className="p5 m-auto mt-5 pt-5 d-flex flex-column align-items-center">
                    <div className={`session-card`} style={{ maxWidth: "auto" }}>       

                        {!docId &&
                            <div className="cj-session">
                                <form onSubmit={(e) => createNewSession(e, sessNameField)}>
                                    <input
                                        type="text"
                                        placeholder="Please enter session name"
                                        onChange={(e) => setSessNameField(e.target.value)}
                                        style={{
                                            outline: '0 !important',
                                            minWidth: '300px'
                                        }}
                                        className="mb-3 form-control"
                                    />
                                    <Button type="submit" className='mb-3 btn-primary-reverse'>Create Session</Button>
                                </form>
                            </div>
                        }

                        {docId &&
                            <div className="container py-3">
                                <h1>{sessionName}</h1>
                                <div className="row pt-2">
                                    <div className="col-12">
                                        <QRCode
                                            style={{
                                                margin: '0 auto',
                                                display: 'block',
                                            }}

                                            size={size}
                                            level='M'
                                            includeMargin={true}
                                            renderAs="svg"
                                            bgColor={bgColor}
                                            fgColor={fgColor}
                                            value={docId}
                                        />
                                        <br />
                                        <br />
                                        <p className="p-url">
                                            <span className="inst-down-links">User Session id : </span>
                                            <span id='copysessid' className="link-col">{docId}</span>
                                            <span onClick={() => CopyToClipboard('copysessid')}>
                                                <img className="copy-url mb-2 ms-1" src={copy_url_logo} alt="copy_session_id" />
                                            </span>
                                        </p>
                                        <p className="p-url">
                                            <span className="inst-down-links">User Session link : </span>
                                            <span id='copysesslink' className="link-col">{window.location.origin + "/join-session/" + docId}</span>
                                            <span onClick={() => CopyToClipboard('copysesslink')}>
                                                <img className="copy-url mb-2 ms-1" src={copy_url_logo} alt="copy_session_url" />
                                            </span>
                                        </p>
                                        <p className="p-url">
                                            <span className="inst-down-links">Presenter link : </span>
                                            <span id='copypreslink' className="link-col">{window.location.origin + "/presenter/" + docId}</span>
                                            <span onClick={() => CopyToClipboard('copypreslink')}>
                                                <img className="copy-url mb-2 ms-1" src={copy_url_logo} alt="copy_presenter_url" />
                                            </span>
                                        </p>
                                        <br />
                                        <Button className='mb-3 btn-primary-reverse' onClick={() => navigate(`/presenter/${docId}`)}>Join session as presenter</Button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>
        </>
    )
}

export default CreateNormalSession