import React from 'react'

function PageHeader({sessName}) {
  return (
    <div className="header-bar container">
        <span className="header-text">{sessName}</span>
    </div>
  )
}

export default PageHeader